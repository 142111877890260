import { gql } from '@apollo/client';

const NOTIFICATION_SUBSELECTION = `
  id
  notificationType
  dateTime
  read
  job {
    id
  }
`;

export const NOTIFICATIONS_QUERY = gql`
  query {
    notifications {
      ${NOTIFICATION_SUBSELECTION}
    }
  }
`;

export const NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription {
    createNotification {
      notification {
        ${NOTIFICATION_SUBSELECTION}
      }
    }
  } 
`;

export const MARK_ALL_AS_READ_MUTATION = gql`
  mutation {
    markAllNotificationsAsRead {
      ids
    }
  }
`;

export const MARK_AS_READ_MUTATION = gql`
  mutation($id: ID) {
    markNotificationAsRead(notificationId: $id) {
      id
    }
  }
`;
