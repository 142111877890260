import { ApolloClient, InMemoryCache } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';

const createWebsocketClient = (token: string) => new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: new WebSocketLink({
    uri: `${process.env.REACT_APP_WEBSOCKETS_PROTOCOL}://${process.env.REACT_APP_API_HOST}/graphql/?token=${token}`,
    options: {
      reconnect: true,
    },
  })
});

export default createWebsocketClient;
