import styled from 'styled-components';
import { JobStatusWrapperProps } from './types';

export const JobStatusWrapper = styled.span<JobStatusWrapperProps>`
  padding: 0.25rem 0.5rem;
  color: white;
  background-color: ${({ theme, status }) => {
    switch (status) {
      case 'OPEN': return theme.color.primary;
      case 'COMPLETED': return 'green';
      case 'CLOSED': return theme.color.danger;
      default: return 'black';
    }
  }};
`;
