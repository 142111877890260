import React from 'react';
import Modal from '../Modal';
import DatePicker from '../DatePicker';
import { DateTimeModalProps } from './types';

const DateTimeModal = ({ isOpen, value, onChange, onToggle }: DateTimeModalProps) => (
  <Modal
    isOpen={isOpen}
    width="xl"
    headerTitle="Pick a date"
    sidebar={false}
    onToggle={onToggle}
  >
    <DatePicker
      value={value}
      onChange={onChange}
    />
  </Modal>
);

export default DateTimeModal;
