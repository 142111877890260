import React from 'react';
import msalClient from 'lib/auth/msalClient';
import SimpleButton from '../SimpleButton';

const Login = () => (
  <div>
    <SimpleButton onClick={() => msalClient.loginRedirect()}>
      Login
    </SimpleButton>
  </div>
);

export default Login;
