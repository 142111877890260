import React from 'react';
import { Link } from 'wouter';
import { useQuery } from '@apollo/client';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons/faUsers';
import { CUSTOMER_DETAIL_QUERY } from './query';
import SimpleButton from '../SimpleButton';
import CurrentPagePortal from '../CurrentPagePortal';
import JobList from '../JobList';

type Test = {
  params: {
    id: string;
  };
};

const CustomerDetail = ({ params }: Test) => {
  const query = useQuery(CUSTOMER_DETAIL_QUERY, { variables: { id: params.id }, fetchPolicy: 'network-only' });
  return query.data ? (
    <>
      <CurrentPagePortal>
        {query.data.customer.logo.length > 0 ? (
          <div className="d-flex">
            <img
              src={query.data.customer.logo}
              alt=""
              className="mr-2"
              style={{ width: 38, height: 38, objectFit: 'cover' }}
            />
            <h2 className="mb-0">
              {query.data.customer.name}
            </h2>
          </div>
        ) : (
          <h2>
            <FontAwesomeIcon icon={faUsers} fixedWidth className="mr-2" />
            {query.data.customer.name}
          </h2>
        )}
      </CurrentPagePortal>
      <Container>
        <div>
          <Link href={`/jobs/create?customerId=${query.data.customer.id}`}>
            <SimpleButton>
              Create job
            </SimpleButton>
          </Link>
          <JobList status="OPEN" jobs={query.data.openJobs} count={query.data.jobStatistics.open} />
        </div>
      </Container>
    </>
  ) : null;
};

export default CustomerDetail;
