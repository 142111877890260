import React from 'react';
import CreatableSelect from '../CreatableSelect';
import { FieldRenderProps } from 'react-final-form';

const CreatableSelectField = ({ input, options, loading, onCreateOption }: FieldRenderProps<any>) => {
  const handleOnCreateOption = (name: string) => {
    input.onChange({ label: name, id: -1 });
    onCreateOption(name)
      .then((newInstance: any) => input.onChange({ label: newInstance.name, value: newInstance.id }));
  };

  return (
    <div>
      <CreatableSelect
        value={input.value}
        onChange={input.onChange}
        options={options}
        isClearable
        isDisabled={loading}
        isLoading={loading}
        onCreateOption={handleOnCreateOption}
      />
    </div>
  );
};

export default CreatableSelectField;
