import { UserAgentApplication } from 'msal';

const msalClient = new UserAgentApplication({
  auth: {
    authority: process.env.REACT_APP_AZURE_AUTHORITY,
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID as string,
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
    validateAuthority: true,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
});

export default msalClient;
