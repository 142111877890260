import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TimeLineItemWrapperProps } from './types';

export const JobHeader = styled.div`
  position: relative;
  border-left: 2px solid ${({ theme }) => theme.color.primary};
  padding-left: 30px;
`;

export const JobInformationPill = styled.div`
  padding: 0.5rem;
  margin-right: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const FurtherActionRequestStatus = styled.span`
  color: ${({ theme, color }) => theme.color[color as string]};
`;

export const TimeLineItemWrapper = styled.div<TimeLineItemWrapperProps>`
  position: relative;
  padding-left: 30px;
  padding-bottom: 50px;
  border-left:
    2px
    ${({ penultimate }) => (penultimate ? 'dashed' : 'solid')}
    ${({ theme, last }) => (last ? 'transparent' : theme.color.primary)};
`;

export const TimelineDateTime = styled.span`
  position: absolute;
  right: calc(100% + 30px);
  width: 100%;
  top: 8px;
  text-align: right;
  color: rgba(0, 0, 0, 0.5)
`;

export const TimeLineItemIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.color.primary};
  position: absolute;
  border-radius: 50%;
  border: 5px solid white;
  background-color: white;
  top: 0;
  left: -21px;
  width: 40px !important;
  height: 40px;
  z-index: 1;
`;

export const TimeLineItemHeader = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
`;

export const TimeLineContentWrapper = styled.div`
  padding: 0.5rem;
`;
