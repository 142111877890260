import { gql } from '@apollo/client';

export const SETTINGS_QUERY = gql`
  query {
    user {
      settings {
        sendAppNotificationForCreationOfOwnJob
        sendEmailNotificationForCreationOfOwnJob
      }
    }
  }
`;

export const UPDATE_SETTINGS_MUTATION = gql`
  mutation($data: UserSettingsInputType) {
    updateSettings(data: $data) {
      settings {
        sendAppNotificationForCreationOfOwnJob
        sendEmailNotificationForCreationOfOwnJob
      }
    }
  }
`;
