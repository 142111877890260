import styled from 'styled-components';
import { NotificationPanelWrapperProps } from './types';

export const NotificationPanelWrapper = styled.div<NotificationPanelWrapperProps>`
  position: absolute;
  transition: all 500ms;
  background-color: white;
  width: 300px;
  height: calc(100vh - 40px);
  overflow-y: auto;
  padding-top: 1rem;
  ${({ open }) => open
    ? `
        right: 0;
        opacity: 1;
      `
    : `
        right: -300px;
        opacity: 0;
      `
  };
  top: 40px; 
`;

export const NotificationWrapper = styled.div`
  cursor: pointer;
  padding: 0.75rem 1rem;
  
  span {
    line-height: 1;
  }
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
