import { gql } from '@apollo/client';

export const CUSTOMERS_QUERY = gql`
  query {
    customers {
      id
      name
    }
  }
`;
