import React, { useState } from 'react';
import { useSearchParam } from 'react-use';
import { Container } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import DateTimeField from '../DateTimeField';
import CreatableSelectField from '../CreatableSelectField';
import JobTimeField from '../JobTimeField';
import FurtherActionRequestField from '../FutherActionRequestField';
import NotifyAdditionalUsersField from '../NotifyAdditionalUsersField';
import { useMutation, useQuery } from '@apollo/client';
import {
  FORM_QUERY,
  CREATE_EMPLOYEE_MUTATION,
  CREATE_INCIDENT_TYPE_MUTATION,
  CREATE_JOB_MUTATION,
} from './query';
import { DateTime } from 'luxon';
import SimpleButton from '../SimpleButton';
import { faPlus } from '@fortawesome/pro-duotone-svg-icons/faPlus';
import { useLocation } from 'wouter';
import CurrentPagePortal from '../CurrentPagePortal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-duotone-svg-icons/faClock';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons/faCheck';

const JobsCreate = () => {
  const customerId = useSearchParam('customerId');
  const [employees, setEmployees] = useState([]);
  const [incidentTypes, setIncidentTypes] = useState([]);
  const query = useQuery(FORM_QUERY, {
    variables: { customerId },
    onCompleted: (data) => {
      setEmployees(data.customer.employees)
      setIncidentTypes(data.incidentTypes);
    },
  });

  const [createEmployee, { loading: creatingEmployee }] = useMutation(CREATE_EMPLOYEE_MUTATION);
  const handleOnCreateEmployee = (name: string) => {
    return createEmployee({ variables: { customerId: query.data.customer.id, data: { name } } })
      .then((response) => {
        setEmployees(response.data.createEmployee.employees)
        return response.data.createEmployee.newEmployee;
      });
  };

  const [createIncidentType, { loading: creatingIncidentType }] = useMutation(CREATE_INCIDENT_TYPE_MUTATION);
  const handleOnCreateIncidentType = (name: string) => {
    return createIncidentType({ variables: { data: { name } } })
      .then((response) => {
        setIncidentTypes(response.data.createIncidentType.incidentTypes)
        return response.data.createIncidentType.newIncidentType;
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setLocation] = useLocation();
  const [createJob, { loading: creatingJob }] = useMutation(CREATE_JOB_MUTATION);
  const handleOnSubmit = (values: any) => {
    createJob({ variables: { customerId: query.data.customer.id, data: values } })
      .then((response) => {
        setLocation(`/jobs/${response.data.createJob.job.id}`);
      });
  };

  const [autoCompletedField, setAutoCompletedField] = useState('');
  return (
    <>
      <CurrentPagePortal>
        <h2>
          <FontAwesomeIcon icon={faClock} fixedWidth className="mr-2" />
          Create a job
        </h2>
      </CurrentPagePortal>
      <Container>
        <div>
          <Form
            initialValues={{
              dateTimeSubmitted: DateTime.local().toISO(),
              dateTimeCompleted: DateTime.local().toISO(),
              furtherActionRequest: {
                required: false,
              },
            }}
            mutators={{
              setDuration: (args, state, utils) => {
                const [dateTimeSubmitted, dateTimeCompleted] = args;
                if (dateTimeSubmitted) {
                  const { dateTimeCompleted } = state.formState.values as any;
                  const duration = Math.floor(DateTime.fromISO(dateTimeCompleted).diff(DateTime.fromISO(dateTimeSubmitted), ['minutes']).minutes)
                  utils.changeValue(state, 'duration', () => duration);
                }
                if (dateTimeCompleted) {
                  const { dateTimeSubmitted } = state.formState.values as any;
                  const duration = Math.floor(DateTime.fromISO(dateTimeCompleted).diff(DateTime.fromISO(dateTimeSubmitted), ['minutes']).minutes)
                  utils.changeValue(state, 'duration', () => duration);
                }
                setAutoCompletedField('duration');
              },
              setDateTimeCompleted: (args, state, utils) => {
                const [duration] = args;
                const { dateTimeSubmitted } = state.formState.values as any;
                const dateTimeCompleted = DateTime.fromISO(dateTimeSubmitted).plus({ minutes: duration || 0 }).toISO();
                utils.changeValue(state, 'dateTimeCompleted', () => dateTimeCompleted);
                setAutoCompletedField('dateTimeCompleted')
              },
            }}
            keepDirtyOnReinitialize
            onSubmit={handleOnSubmit}
            render={({ handleSubmit, form, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <Field
                    name="dateTimeSubmitted"
                    label="Start"
                    component={DateTimeField}
                    parse={(value) => {
                      form.mutators.setDuration(value, null);
                      return value;
                    }}
                  />
                </div>
                <div className="mb-5">
                  <p>
                    Raised by
                  </p>
                  <Field
                    name="raisedBy"
                    options={employees.map((employee: any) => ({ label: employee.name, value: employee.id }))}
                    loading={query.loading || creatingEmployee}
                    onCreateOption={handleOnCreateEmployee}
                    component={CreatableSelectField}
                  />
                </div>
                <div className="mb-5">
                  <p>
                    Incident Type
                  </p>
                  <Field
                    name="incidentType"
                    options={incidentTypes
                      .map((incidentType: any) => ({ label: incidentType.name, value: incidentType.id }))}
                    loading={query.loading || creatingIncidentType}
                    onCreateOption={handleOnCreateIncidentType}
                    component={CreatableSelectField}
                  />
                </div>
                <div className="mb-5">
                  <p>
                    Duration
                    {autoCompletedField === 'duration' && <span className="ml-1 text-black-50">(autocompleted)</span>}
                  </p>
                  <Field
                    name="duration"
                    component={JobTimeField}
                    parse={(value) => {
                      form.mutators.setDateTimeCompleted(value);
                      return value;
                    }}
                  />
                </div>
                <div className="mb-5">
                  <Field
                    name="dateTimeCompleted"
                    autocompleted={autoCompletedField === 'dateTimeCompleted'}
                    label="End"
                    parse={(value) => {
                      form.mutators.setDuration(null, value);
                      return value;
                    }}
                    component={DateTimeField}
                  />
                </div>
                <div className="mb-5">
                  <Field
                    name="furtherActionRequest"
                    component={FurtherActionRequestField}
                  />
                </div>
                <div>
                  <Field
                    name="notifyAdditionalUsers"
                    component={NotifyAdditionalUsersField}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  {values.furtherActionRequest.required ? (
                    <SimpleButton icon={faPlus} loading={creatingJob} disabled={query.loading} color="primary">
                      Create job and request further action
                    </SimpleButton>
                  ) : (
                    <SimpleButton icon={faCheck} loading={creatingJob} disabled={query.loading}>
                      Complete job
                    </SimpleButton>
                  )}
                </div>
              </form>
            )}
          />
        </div>
      </Container>
    </>
  );
};

export default JobsCreate;
