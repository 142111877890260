import React from 'react';
import classNames from 'classnames';
import { JobStatusType } from './types';
import { JobStatusWrapper } from './styled';

const JobStatus: JobStatusType = ({ className, status }) => (
  <JobStatusWrapper status={status} className={classNames('rounded', className)}>
    {status}
  </JobStatusWrapper>
);

export default JobStatus;
