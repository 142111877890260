import React, { useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import Select from '../Select';
import Checkbox from '../Checkbox';
import TextareaField from '../TextareaField';
import { useLazyQuery } from '@apollo/client';
import { USERS_QUERY } from './query';

const FurtherActionRequestField = ({ input, optional }: FieldRenderProps<any>) => {
  const [users, setUsers] = useState([]);
  const [getUsers, { loading: gettingUsers }] = useLazyQuery(USERS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => setUsers(data.users),
  });
  useEffect(() => {
    if (!optional || input.value.required) getUsers();
  }, [getUsers, input.value.required, optional]);

  const handleRequiredOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    input.onChange({ ...input.value, required: event.target.checked });
  };

  const handlePersonOnChange = (value: any) => {
    input.onChange({ ...input.value, person: value });
  };

  const handleNotesOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    input.onChange({ ...input.value, notes: event.target.value });
  };

  return (
    <div>
      {optional && (
        <div className="mb-3">
          <Checkbox
            label="Further action required"
            checked={input.value.required}
            onChange={handleRequiredOnChange}
          />
        </div>
      )}
      {(!optional || input.value.required) && (
        <div>
          <div className="mb-3">
            <p>Person</p>
            <Select
              loading={gettingUsers}
              options={users.map((user: any) => ({ label: user.name, value: user.id }))}
              value={input.value.person}
              onChange={handlePersonOnChange}
            />
          </div>
          <div>
            <p>Notes</p>
            <TextareaField
              value={input.value.notes}
              onChange={handleNotesOnChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

FurtherActionRequestField.defaultProps = {
  optional: true,
};

export default FurtherActionRequestField;
