import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { InputStyled } from '../Input/styled';

const JobTimeField = ({ input, readOnly, readOnlyValue, ...rest }: FieldRenderProps<number>) => (
  <div>
    <InputStyled
      className="rounded w-25"
      placeholder="Minutes"
      type="number"
      value={readOnly ? readOnlyValue : input.value}
      onChange={input.onChange}
      {...rest}
    />
  </div>
);

export default JobTimeField;
