import React from 'react';
import { Link } from 'wouter';
import { JobListItemWrapper, JobStatistic } from './styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-duotone-svg-icons/faClock';
import { DateTime } from 'luxon';

const JobListItem = ({ id, customer, status, incidentType, dateTime }: any) => (
  <Link key={id} to={`/jobs/${id}`}>
    <JobListItemWrapper status={status}>
      <FontAwesomeIcon icon={faClock} />
      <div className="d-flex justify-content-between">
        <span className="text-black-50 mb-0">{id} - <strong>{customer.name}</strong></span>
        <span className="text-black-50">
        {DateTime.fromISO(dateTime).toLocaleString(DateTime.DATETIME_MED)}
      </span>
      </div>
      <div>
        {incidentType.name}
      </div>
    </JobListItemWrapper>
  </Link>
);

const JobList = ({ status, jobs, count }: any) => (
  <div>
    <JobStatistic status={status}>
      <h3>{count}</h3>
      <div className="d-flex justify-content-between">
        <p className="text-capitalize">{status.toLowerCase()}</p>
        <p className="text-black-50">
          <small>Showing {jobs.length} job{jobs.length === 1 ? '' : 's'}</small>
        </p>
      </div>
    </JobStatistic>
    <div>
      {jobs.length
        ? jobs.map((job: any) => (
          <JobListItem key={job.id} dateTime={job.dateTimeSubmitted} {...job} />
        ))
        : <div className="p-3"><p className="text-black-50">No {status.toLowerCase()} jobs</p></div>}
    </div>
  </div>
);

export default JobList;
