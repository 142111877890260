import styled from 'styled-components';

export const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHead = styled.thead``;

export const TableBody = styled.thead``;

export const TableHeadRow = styled.tr`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const TableRow = styled.tr`
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const TableHeadCell = styled.th`
  padding: 0 1rem;
`;

export const TableCell = styled.td`
  padding: 1rem;
`;

const Table = {
  Wrapper: TableWrapper,
  Head: TableHead,
  Body: TableBody,
  HeadRow: TableHeadRow,
  Row: TableRow,
  HeadCell: TableHeadCell,
  Cell: TableCell,
}

export default Table;
