import { gql } from '@apollo/client';

export const CREATE_CUSTOMER_MUTATION = gql`
  mutation($data: CustomerInputType) {
    createCustomer(data: $data) {
      customer {
        id
      }
    }
  }
`;
