import styled from 'styled-components';

const PageSpacer = styled.div`
  max-height: calc(100vh - 165px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 4rem;
`;

export default PageSpacer;
