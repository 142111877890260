import React from 'react';
import Select from '../Select';
import { FieldRenderProps } from 'react-final-form';

const SelectField = ({ input, options, ...rest }: FieldRenderProps<any>) => (
  <div>
    <Select
      value={input.value}
      options={options}
      onChange={input.onChange}
      {...rest}
    />
  </div>
);

export default SelectField;
