import styled from 'styled-components';

export const ModalHeaderStyled = styled.div`
  background-color: white;
  border-bottom: 1px solid #dee2e6;
  padding: 1rem 2rem;
  color: white;
  text-align: left;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  display: flex;
  justify-content: space-between;
  
  svg, h4 {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const ModalSidebarStyled = styled.div`
  border-right: 1px solid #dee2e6;
  background-color: white;
  height: 100%;
  color: black;
  border-bottom-left-radius: 0.75rem;
`;

type ModalContentStyled = {
  modalHasSidebar?: boolean;
};

export const ModalContentStyled = styled.div<ModalContentStyled>`
  background-color: white;
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: ${({ modalHasSidebar }) => (modalHasSidebar ? 0 : '0.75rem')};
  padding: 2rem;
`;

export const ModalFooterStyled = styled.div`
  padding-top: 1rem;
`;
