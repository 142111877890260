import styled from 'styled-components';
import { BreadcrumbElementWrapperProps } from './types';

export const BreadcrumbElementWrapper = styled.div<BreadcrumbElementWrapperProps>`
  text-transform: capitalize;

  ${({ last }) => {
    if (last) return `
      color: black;
    `;
    return `
      color: rgba(0, 0, 0, 0.8);
      cursor: pointer;
    
      &:hover {
        color: rgba(0, 0, 0, 0.5);
      };  
    `;
  }}
`;

export const BreadcrumbSeparatorWrapper = styled.div`
  font-size: 0.75rem;
  line-height: 1.5rem;
  margin: 0 0.5rem;
  color: black;
`;
