import React from 'react';
import classNames from 'classnames';
import { SimpleButtonProps } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { ButtonWithRemovedStyles } from './styled';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const SimpleButton: React.FC<SimpleButtonProps> = ({
  icon,
  iconStyle,
  iconSide,
  loading,
  loadingIcon = faSpinnerThird,
  children,
  style,
  backgroundColor,
  className,
  color,
  value,
  hoverStyles,
  disabled,
  onClick,
  type,
  ...rest
}: SimpleButtonProps) => (
  <ButtonWithRemovedStyles
    onClick={onClick}
    style={style}
    backgroundColor={backgroundColor}
    className={className}
    value={value}
    hoverStyles={hoverStyles}
    disabled={disabled}
    type={type}
    color={color}
    {...rest}
  >
    {icon && iconSide === 'left' && (
      <>
        {loading ? (
          <FontAwesomeIcon
            style={iconStyle}
            icon={loadingIcon}
            spin={loading}
            fixedWidth
            className={classNames({ 'mr-1': !!children })}
          />
        ) : (
          <FontAwesomeIcon
            style={iconStyle}
            icon={icon}
            spin={loading}
            fixedWidth
            className={classNames({ 'mr-1': !!children })}
          />
        )}
      </>
    )}
    {children}
    {icon && iconSide === 'right' && (
      <>
        {loading ? (
          <FontAwesomeIcon
            style={iconStyle}
            icon={loadingIcon}
            spin={loading}
            fixedWidth
            className={classNames({ 'ml-1': !!children })}
          />
        ) : (
          <FontAwesomeIcon
            style={iconStyle}
            icon={icon}
            spin={loading}
            fixedWidth
            className={classNames({ 'ml-1': !!children })}
          />
        )}
      </>
    )}
  </ButtonWithRemovedStyles>
);

SimpleButton.defaultProps = {
  icon: null as unknown as IconProp,
  iconSide: 'left',
  loading: false,
  loadingIcon: faSpinnerThird,
  hoverStyles: true,
  disabled: false,
};

export default SimpleButton;
