import React from 'react';
import classNames from 'classnames';
import { Link } from 'wouter';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-duotone-svg-icons/faClock';
import { NotificationPanelProps } from './types';
import { NotificationPanelWrapper, NotificationWrapper } from './styled';
import SimpleButton from '../SimpleButton';

const NotificationPanel = ({ open, notifications, markAllAsRead, markAsRead }: NotificationPanelProps) => {
  const renderContent = ({ id, notificationType, job, read, dateTime }: any) => {
    switch (notificationType) {
      case 'CREATE_JOB':
        return (
          <Link
            to={`/jobs/${job.id}`}
            onClick={() => {
              if (!read) markAsRead(id);
            }}
          >
            <NotificationWrapper className="d-flex mb-3">
              <div className="d-flex flex-column justify-content-center mr-2">
                <FontAwesomeIcon icon={faClock} size="2x" />
              </div>
              <div>
              <span className={classNames('d-block', { 'font-weight-bold': !read })}>
                Job created
              </span>
                <span className="d-block text-black-50 text-capitalize">
                  <small>
                    {DateTime.fromISO(dateTime).toRelativeCalendar()}
                  </small>
                </span>
              </div>
            </NotificationWrapper>
          </Link>
        );
      default:
        return null;
    }
  };

  return (
    <NotificationPanelWrapper open={open} className="shadow-lg">
      <div className="d-flex justify-content-between p-1 border-bottom ml-3">
        <div>
          <p className="font-weight-bold mb-0">Notifications</p>
        </div>
        {notifications.filter((notification) => !notification.read).length > 0 && (
          <div>
            <SimpleButton hoverStyles={false} onClick={markAllAsRead}>
            <span className="text-black-50">
              Mark all as read
            </span>
            </SimpleButton>
          </div>
        )}
      </div>
      {notifications.map((notification) => (
        <div key={notification.id}>
          {renderContent(notification)}
        </div>
      ))}
    </NotificationPanelWrapper>
  );
};

export default NotificationPanel;
