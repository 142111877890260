import styled from 'styled-components';

export const TextareaStyled = styled.textarea`
  width: 100%;
  min-height: 150px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  
  &:focus {
    background: 2px solid ${({ theme }) => theme.color.primary};
  }
`;
