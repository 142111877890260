import React from 'react';
import classNames from 'classnames';

import { Modal as BaseModal, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons/faQuestionCircle';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons/faTimesCircle';
import SimpleButton from '../SimpleButton';
import {
  ModalHeaderStyled,
  ModalSidebarStyled,
  ModalContentStyled,
  ModalFooterStyled,
} from './styled';

interface ModalHeaderProps {
  title?: string;
  isStatic?: boolean;
  onHelp?: () => void;
  onClose: () => void;
}

export const ModalHeader = ({
  title,
  isStatic = false,
  onHelp,
  onClose,
}: ModalHeaderProps): JSX.Element => (
  <ModalHeaderStyled>
    <h4 className="mb-0">
      {title}
    </h4>
    <div style={{ height: 24 }}>
      <SimpleButton hoverStyles={false} onClick={onHelp} className="mr-3">
        <FontAwesomeIcon style={{ fontSize: '1.5rem' }} icon={faQuestionCircle} />
      </SimpleButton>
      {!isStatic && (
        <SimpleButton hoverStyles={false} onClick={onClose}>
          <FontAwesomeIcon style={{ fontSize: '1.5rem' }} icon={faTimesCircle} />
        </SimpleButton>
      )}
    </div>
  </ModalHeaderStyled>
);

interface ModalSidebarProps {
  icon?: IconProp;
  heading?: string;
  body?: string;
  children?: JSX.Element;
}

export const ModalSidebar = ({
  icon,
  heading,
  body,
  children,
}: ModalSidebarProps): JSX.Element => (
  <ModalSidebarStyled className={classNames({ 'p-3 pt-5': !children })}>
    <div className="text-black-50">
      {heading && (
        <h5 className="text-uppercase">
          {icon && (
            <FontAwesomeIcon icon={icon} className="mr-1" />
          )}
          <span>
            {heading}
          </span>
        </h5>
      )}
      {body && (
        <>
          <hr />
          <p className="lead">
            {body}
          </p>
        </>
      )}
    </div>
    {children}
  </ModalSidebarStyled>
);

export const ModalContent = ModalContentStyled;

export const ModalFooter = ModalFooterStyled;

type widthKeys = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const getWidth = (width: widthKeys): number | string => ({
  sm: 200,
  md: 400,
  lg: 600,
  xl: 800,
  xxl: 1200,
}[width]);

interface ModalProps {
  isOpen: boolean;
  width: widthKeys;
  isStatic?: boolean;
  hasFooter?: boolean;
  children: JSX.Element;
  headerTitle?: ModalHeaderProps['title'];
  sidebar?: boolean;
  sidebarIcon?: ModalSidebarProps['icon'];
  sidebarHeading?: ModalSidebarProps['heading'];
  sidebarBody?: ModalSidebarProps['body'];
  sidebarChildren?: ModalSidebarProps['children'];
  footerChildren?: JSX.Element;
  onToggle: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  onHelp?: () => void;
  onClosed?: () => void;
}

const Modal = ({
  isOpen,
  width,
  children,
  isStatic = false,
  hasFooter = true,
  headerTitle,
  sidebar = true,
  sidebarIcon,
  sidebarHeading,
  sidebarBody,
  sidebarChildren,
  footerChildren,
  onToggle,
  onHelp,
  onClosed,
}: ModalProps): JSX.Element => {
  const renderModalContent = () => (
    <ModalContent modalHasSidebar={sidebar}>
      {children}
      {hasFooter && (
        <ModalFooter>
          {footerChildren}
        </ModalFooter>
      )}
    </ModalContent>
  );

  return (
    <BaseModal
      isOpen={isOpen}
      centered
      toggle={onToggle}
      onClosed={onClosed}
      style={{ width: getWidth(width) }}
    >
      <ModalHeader
        title={headerTitle}
        isStatic={isStatic}
        onHelp={onHelp}
        onClose={onToggle}
      />
      {sidebar ? (
        <Row noGutters>
          <Col lg={3} xl={2}>
            <ModalSidebar
              icon={sidebarIcon}
              heading={sidebarHeading}
              body={sidebarBody}
            >
              {sidebarChildren}
            </ModalSidebar>
          </Col>
          <Col>
            {renderModalContent()}
          </Col>
        </Row>
      ) : renderModalContent()}
    </BaseModal>
  );
};

export default Modal;
