import React from 'react';
import { Link } from 'wouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons/faUsers';
import { faClock } from '@fortawesome/pro-duotone-svg-icons/faClock';
import { faCog } from '@fortawesome/pro-duotone-svg-icons/faCog';
import { SidebarWrapper, SidebarIconCategoryHeader, SidebarIcon } from './styled';

const Sidebar = () => (
  <SidebarWrapper className="d-flex flex-column">
    <div>
      <Link href="/customers">
        <SidebarIcon>
          <FontAwesomeIcon icon={faUsers} fixedWidth />
          <p>Customers</p>
        </SidebarIcon>
      </Link>
      <Link href="/jobs">
        <SidebarIcon>
          <FontAwesomeIcon icon={faClock} fixedWidth />
          <p>Jobs</p>
        </SidebarIcon>
      </Link>
      <br />
      <SidebarIconCategoryHeader>
        <p className="mb-0">Admin</p>
      </SidebarIconCategoryHeader>
      <Link href="/settings">
        <SidebarIcon>
          <FontAwesomeIcon icon={faCog} fixedWidth />
          <p>Settings</p>
        </SidebarIcon>
      </Link>
    </div>
  </SidebarWrapper>
);

export default Sidebar;
