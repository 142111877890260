import { gql } from '@apollo/client';

export const GENERATE_WEBSOCKET_TOKEN_MUTATION = gql`
  mutation {
    generateWebsocketToken {
      websocketToken {
        token      
      }
    }
  }
`;
