import { gql } from '@apollo/client';

const JOB_SUBSELECTION = `
  id
  dateTimeSubmitted
  dateTimeCompleted
  dateTimeClosed
  status
  incidentType {
    id
    name
  }
  customer {
    id
    name
  }
`;

export const CUSTOMER_DETAIL_QUERY = gql`
  query($id: ID) {
    customer(customerId: $id) {
      id
      name
      logo
    }
    jobStatistics(customerId: $id) {
      open
    }
    openJobs(customerId: $id) {
      ${JOB_SUBSELECTION}
    }
  }
`;
