import styled from 'styled-components';
import { Row } from 'reactstrap';

export const CustomerEmployeeFieldStyled = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.1);
`;

export const TableRow = styled(Row)`
  padding: 0.5rem 0;

  span {
    font-size: 1.25rem;
  }
  
  svg {
    color: ${({ theme }) => theme.color.primary};
  }
`;
