import { gql } from '@apollo/client';

const JOB_SUBSELECTION = `
  id
  dateTimeSubmitted
  dateTimeCompleted
  dateTimeClosed
  status
  incidentType {
    id
    name
  }
  customer {
    id
    name
  }
`;

export const JOBS_QUERY = gql`
  query {
    jobStatistics {
      open
      completed
      closed
    }
    openJobs {
      ${JOB_SUBSELECTION}
    }
    completedJobs {
      ${JOB_SUBSELECTION}
    }
    closedJobs {
      ${JOB_SUBSELECTION}
    }
  }
`;
