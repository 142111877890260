import styled from 'styled-components';
import { JobStatisticProps, JobListItemWrapperProps } from './types';

export const JobStatistic = styled.div.attrs<JobStatisticProps>(({ theme, status }) => {
  let color;
  switch (status) {
    case 'OPEN': {
      color = theme.color.primary;
      break;
    }
    case 'COMPLETED': {
      color = 'green';
      break;
    }
    case 'CLOSED': {
      color = theme.color.danger;
      break;
    }
    default: {
      color = 'black';
    }
  }
  return { color };
})<JobStatisticProps>`
  padding: 0 1rem 1rem 1rem;
  border-left: 2px solid ${({ color }) => color};
  border-bottom: 2px solid ${({ color }) => color};

  h3 {
    margin-bottom: 0;
    color: ${({ color }) => color}
  }
  
  p {
    margin-bottom: 0;
  }
`;

export const JobListItemWrapper = styled.div.attrs<JobListItemWrapperProps>(({ theme, status }) => {
  let color;
  switch (status) {
    case 'OPEN': {
      color = theme.color.primary;
      break;
    }
    case 'COMPLETED': {
      color = 'green';
      break;
    }
    case 'CLOSED': {
      color = theme.color.danger;
      break;
    }
    default: {
      color = 'black';
    }
  }
  return { color };
})<JobListItemWrapperProps>`
  position: relative;
  padding: 1rem;
  border-left: 2px solid ${({ color }) => color};
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;

  svg {
    position: absolute;
    left: -9px;
    top: 20px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid white;
    transition: transform 200ms;
  }
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    
    svg {
      transform: scale(1.25);
    }
  }
`;
