import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons/faUserCircle';
import { faPlusCircle } from '@fortawesome/pro-duotone-svg-icons/faPlusCircle';
import { faPlus } from '@fortawesome/pro-duotone-svg-icons/faPlus';
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes';
import SimpleButton from '../SimpleButton';
import { InputStyled } from '../Input/styled';
import { CustomerEmployeeFieldStyled, TableRow } from './styled';

const CustomerEmployeeField = ({ input, className }: FieldRenderProps<any>) => {
  const [name, setName] = useState('');
  const handleOnAdd = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!name) return;
    const [highestId] = input.value && input.value.length > 0
      ? input.value.map((employee: any) => employee.id).sort().reverse()
      : [0];
    input.onChange([
      ...input.value,
      { id: highestId + 1, name },
    ]);
    setName('');
  };

  const handleOnRemove = (id: number) => {
    input.onChange(input.value.filter((employee: any) => employee.id !== id));
  };

  return (
    <CustomerEmployeeFieldStyled className={className}>
      <Row>
        <Col xs={8}>
          <h5 className="font-weight-bold" style={{ marginLeft: 44 }}>
            Employee name
          </h5>
        </Col>
      </Row>
      {input.value && input.value.map((employee: any) => (
        <TableRow key={employee.id}>
          <Col xs={8}>
            <div className="d-flex">
              <FontAwesomeIcon className="mr-1" icon={faUserCircle} fixedWidth size="2x" />
              <div className="d-flex flex-column justify-content-center">
              <span>
                {employee.name}
              </span>
              </div>
            </div>
          </Col>
          <Col>
            <SimpleButton
              hoverStyles
              icon={faTimes}
              onClick={(event) => {
                event.preventDefault();
                handleOnRemove(employee.id);
              }}
            />
          </Col>
        </TableRow>
      ))}
      <Row>
        <Col xs={8}>
          <div className="d-flex h-100">
            <div className="d-flex flex-column justify-content-center h-100">
              <FontAwesomeIcon className="mr-1" icon={faPlusCircle} fixedWidth size="2x" />
            </div>
            <InputStyled
              className="pl-0 rounded font-weight-normal"
              style={{ fontSize: '1.25rem' }}
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
        </Col>
        <Col>
          <SimpleButton hoverStyles icon={faPlus} onClick={handleOnAdd}>
            Add employee
          </SimpleButton>
        </Col>
      </Row>
    </CustomerEmployeeFieldStyled>
  );
};

export default CustomerEmployeeField;
