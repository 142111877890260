import styled from 'styled-components';

export const ColumnHeader = styled.div`
  width: 100%;
`;

export const Row = styled.div`
  border-bottom: ${({ theme }) => theme.misc.border};
`;

type CellProps = {
  darker: boolean;
  outline: boolean;
  selected: boolean;
};

export const Cell = styled.div<CellProps>`
  width: 100%;
  height: 100px;
  border: ${({ theme, outline }) => (outline ? theme.misc.border : 'none')};
  font-weight: 400;  
  transition: background-color 100ms;
  background-color: ${({
    theme,
    selected,
    darker,
  }) => {
    if (selected) {
      return theme.color.primary;
    }
    if (darker) {
      return '#f8f8f8';
    }
    return 'white';
  }};
  color: ${({ selected }) => (selected ? 'white' : 'black')};
  
  &:hover {
    font-weight: 600;
    cursor: pointer;
    background-color: ${({ theme, selected }) => (selected ? theme.color.primary : 'rgba(0, 0, 20, 0.175)')};
  }
`;
