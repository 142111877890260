import {
  ApolloClient,
  InMemoryCache,
  from,
  createHttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context';
import { msalClient } from '../auth';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_HTTP_PROTOCOL}://${process.env.REACT_APP_API_HOST}/graphql/`,
});

const authLink = setContext((query, options) => {
  return new Promise((onSuccess) => {
    const request = { scopes: ['api://44477914-f113-4789-b749-b621b71a6728/all-endpoints'] };
    msalClient.acquireTokenSilent(request)
      .then((response) => {
        onSuccess({
          ...options,
          headers: {
            ...options?.headers,
            Authorization: `Bearer ${response.accessToken}`,
          },
        })
      })
      .catch(() => msalClient.loginRedirect());
  })
});

const httpClient = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: from([
    authLink,
    httpLink,
  ]),
})

export default httpClient;
