import React, { Fragment } from 'react';
import { Link } from 'wouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons/faChevronRight';
import { BreadcrumbItem, BreadcrumbProps } from './types';
import { BreadcrumbElementWrapper, BreadcrumbSeparatorWrapper } from './styled';

const Breadcrumb = ({ items }: BreadcrumbProps) => {
  const withLink = (item: any, renderItem: any) => (
    <Link href={item.url}>
      {renderItem(item)}
    </Link>
  );

  const renderSeparator = () => (
    <BreadcrumbSeparatorWrapper>
      <FontAwesomeIcon icon={faChevronRight} fixedWidth />
    </BreadcrumbSeparatorWrapper>
  );

  const renderElement = (item: BreadcrumbItem, index: number) => (
    <BreadcrumbElementWrapper key={item.url} last={index === items.length - 1}>
      {item.name}
    </BreadcrumbElementWrapper>
  );

  const renderItem = (item: BreadcrumbItem, index: number) => {
    return (
      <Fragment key={item.url}>
        {withLink(item, renderElement)}
        {renderSeparator()}
      </Fragment>
    );
  };

  return (
    <div className="d-flex">
      {items.map((item, index) => renderItem(item, index))}
    </div>
  );
};

export default Breadcrumb;
