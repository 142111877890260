import React, { useContext, useState } from 'react';
import { useLocation, Link } from 'wouter';
import { useToggle } from 'react-use';
import { msalClient } from 'lib/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons/faUserCircle';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import Breadcrumb from '../Breadcrumb';
import {
  TopbarWrapper,
  NavbarWrapper,
  NavbarLink,
  NavbarSearchBar,
  NavbarSearchBarInput,
  NotificationBellWrapper,
} from './styled';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faBell } from '@fortawesome/pro-duotone-svg-icons/faBell';
import Portal from '../Portal';
import NotificationPanel from '../NotificationPanel';
import { OnSubscriptionDataOptions, useMutation, useQuery, useSubscription } from '@apollo/client';
import {
  NOTIFICATIONS_QUERY,
  NOTIFICATIONS_SUBSCRIPTION,
  MARK_ALL_AS_READ_MUTATION,
  MARK_AS_READ_MUTATION,
} from './query';
import { WebsocketClientContext } from '../App/context';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';

const TopbarNotifications = ({ websocketClient }: any) => {
  const [notifications, setNotifications] = useState<any[]>([] as any[]);
  useQuery(NOTIFICATIONS_QUERY, {
    onCompleted: (data: any) => {
      setNotifications(data.notifications)

    },
  });
  useSubscription(NOTIFICATIONS_SUBSCRIPTION, {
    client: websocketClient,
    onSubscriptionData: ({ subscriptionData }: OnSubscriptionDataOptions) => {
      setNotifications((prevNotifications: any[]) => ([
        subscriptionData.data.createNotification.notification,
        ...prevNotifications,
      ]));
    },
  });

  const [markAllAsRead] = useMutation(MARK_ALL_AS_READ_MUTATION, {
    onCompleted: (data) => {
      setNotifications((notifications: any[]) => notifications.map((notification: any) => {
        if (data.markAllNotificationsAsRead.ids.includes(notification.id)) {
          return { ...notification, read: true };
        }
        return notification;
      }));
    },
  });

  const [markAsRead] = useMutation(MARK_AS_READ_MUTATION, {
    onCompleted: (data) => {
      setNotifications((notifications: any[]) => notifications.map((notification: any) => {
        if (data.markNotificationAsRead.id === notification.id) {
          return { ...notification, read: true };
        }
        return notification;
      }));
    },
  });
  const handleMarkAsRead = (id: string) => markAsRead({ variables: { id } });

  const [notificationPanelOpen, toggleNotificationPanel] = useToggle(false);
  const handleToggleNotificationPanel = () => {
    toggleNotificationPanel();
  };
  return (
    <>
      <NavbarLink onClick={handleToggleNotificationPanel} active={notificationPanelOpen}>
        <NotificationBellWrapper>
          {notifications.filter((notification) => !notification.read).length > 0 && <div />}
          <FontAwesomeIcon icon={faBell} style={{ height: 20, width: 20, marginTop: 10 }} />
        </NotificationBellWrapper>
      </NavbarLink>
      <Portal element={document.body}>
        <NotificationPanel
          open={notificationPanelOpen}
          notifications={notifications}
          markAllAsRead={markAllAsRead}
          markAsRead={handleMarkAsRead}
        />
      </Portal>
    </>
  );
};

const Topbar = () => {
  const [location] = useLocation();
  const elements = location
    .replace(/\[.*?\]/g, '')
    .split('/')
    .filter((element) => element.length !== 0);
  const getItems = () => ([
    {
      name: 'Home',
      url: '/'
    },
    ...elements.map((element) => ({
      name: element,
      url: `/${elements.slice(0, elements.indexOf(element) + 1).join('/')}`,
    }))
  ]);

  const websocketClient = useContext(WebsocketClientContext);
  const account = msalClient.getAccount();
  const breadcrumbItems = getItems();
  return (
    <>
      <TopbarWrapper>
        <NavbarWrapper>
          <div className="d-flex justify-content-between h-100">
            <div className="d-flex flex-grow-1">
              <NavbarLink>
                <FontAwesomeIcon
                  className="text-white-50"
                  icon={faBars}
                  style={{ height: 24, width: 24, margin: '7px 0' }}
                />
              </NavbarLink>
              <Link to="/">
                <NavbarLink>
                  CNS Portal
                </NavbarLink>
              </Link>
            </div>
            <div className="flex-grow-1">
              <NavbarSearchBar>
                <FontAwesomeIcon icon={faSearch} />
                <NavbarSearchBarInput placeholder="Search (/)" className="w-100" />
              </NavbarSearchBar>
            </div>
            <div className="d-flex flex-grow-1 justify-content-end">
              {typeof websocketClient === 'undefined'
                ? (
                  <NavbarLink>
                    <FontAwesomeIcon icon={faSpinnerThird} style={{ height: 20, width: 20, marginTop: 10 }} spin />
                  </NavbarLink>
                )
                : <TopbarNotifications websocketClient={websocketClient} />
              }
              <Link to="/profile">
                <NavbarLink href="/profile" className="px-3 d-flex">
                  <p className="mr-2">
                    {account?.userName}
                  </p>
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    style={{ height: 30, width: 30, marginTop: 5 }}
                  />
                </NavbarLink>
              </Link>
            </div>
          </div>
        </NavbarWrapper>
        <div className="ml-3 mt-3">
          <Breadcrumb items={breadcrumbItems.slice(0, breadcrumbItems.length - 1)} />
        </div>
      </TopbarWrapper>
    </>
  );
};

export default Topbar;
