import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'wouter';
import { ApolloClient, ApolloProvider, useMutation, useQuery } from '@apollo/client';
import { createWebsocketClient, httpClient } from 'lib/apollo';
import { ThemeProvider } from 'styled-components';
import theme from '@cnsuk/cnsuk-theme';
import Topbar from '../Topbar';
import Sidebar from '../Sidebar';
import PageSpacer from '../PageSpacer';
import Login from '../Login';
import ProtectedRoute from '../ProtectedRoute';
import Dashboard from '../Dashboard';
import Customers from '../Customers';
import CustomersCreate from '../CustomersCreate';
import CustomerDetail from '../CustomerDetail';
import Jobs from '../Jobs';
import JobsCreate from '../JobsCreate';
import JobDetail from '../JobDetail';
import Settings from '../Settings';
import CurrentPage from '../CurrentPage';
import { GENERATE_WEBSOCKET_TOKEN_MUTATION } from './query';
import { WebsocketClientContext } from './context';

const RoutesWithLayout = () => (
  <>
    <Topbar />
    <CurrentPage />
    <Sidebar />
    <PageSpacer>
      <Switch>
        <ProtectedRoute path="/" component={Dashboard} />
        <ProtectedRoute path="/customers" component={Customers} />
        <ProtectedRoute path="/customers/create" component={CustomersCreate} />
        <ProtectedRoute path="/customers/:id" component={CustomerDetail} />
        <ProtectedRoute path="/jobs" component={Jobs} />
        <ProtectedRoute path="/jobs/create" component={JobsCreate} />
        <ProtectedRoute path="/jobs/:id" component={JobDetail} />
        <ProtectedRoute path="/settings" component={Settings} />
      </Switch>
    </PageSpacer>
  </>
);

const App = () => {
  const [websocketClient, setWebsocketClient] = useState<ApolloClient<any> | undefined>(undefined);
  const [generateWebsocketToken] = useMutation(GENERATE_WEBSOCKET_TOKEN_MUTATION, {
    client: httpClient,
    onCompleted: (data) => setWebsocketClient(createWebsocketClient(data.generateWebsocketToken.websocketToken.token)),
  })
  useEffect(() => {
    generateWebsocketToken();
  }, [generateWebsocketToken]);

  return (
    <ApolloProvider client={httpClient}>
      <WebsocketClientContext.Provider value={websocketClient}>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route component={RoutesWithLayout} />
          </Switch>
        </ThemeProvider>
      </WebsocketClientContext.Provider>
    </ApolloProvider>
  );
};

export default App;

