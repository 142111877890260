import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { FieldRenderProps } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-duotone-svg-icons/faCalendarAlt';
import DateModal from '../DateTimeModal';
import Select from '../Select';
import { DateFieldWrapper, OpenDatePickerButton } from './styled';

const DateTimeField = ({ input, label, autocompleted }: FieldRenderProps<any>) => {
  const [dateTimeModalIsOpen, setDateTimeModalIsOpen] = useState(false);

  const handleOnToggleDateModal = (event?: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>) => {
    if (event) event.preventDefault();
    setDateTimeModalIsOpen(!dateTimeModalIsOpen);
  };

  const handleOnChangeDate = (dateString: string) => {
    const { year, month, day } = DateTime.fromISO(dateString);
    input.onChange(DateTime.fromISO(input.value).set({ year, month, day }).toISO());
    handleOnToggleDateModal();
  };

  const handleOnChangeHour = (value: any) => {
    input.onChange(DateTime.fromISO(input.value).set({ hour: value.value }).toISO());
  };

  const handleOnChangeMinute = (value: any) => {
    input.onChange(DateTime.fromISO(input.value).set({ minute: value.value }).toISO());
  };

  const getDate = () => {
    const date = new Date(input.value);
    return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_FULL);
  };

  const getHourOptions = () => {
    const hours = Array.from({ length: 24 }, (_, index) => index);
    return hours.map((hour) => ({ label: hour, value: hour }));
  };

  const getMinuteOptions = () => {
    const minutes = Array.from({ length: 60 }, (_, index) => index);
    return minutes.map((minute) => ({ label: minute, value: minute }));
  };

  return (
    <>
      <div className="d-flex w-100">
        <div className="flex-grow-1">
          <p>
            {label && `${label} `}Date
            {autocompleted && <span className="ml-1 text-black-50">(autocompleted)</span>}
          </p>
          <DateFieldWrapper className="d-flex rounded" onClick={handleOnToggleDateModal}>
            <p className="mb-0 flex-grow-1 text-left">{getDate()}</p>
            <OpenDatePickerButton>
              <FontAwesomeIcon icon={faCalendarAlt} fixedWidth />
            </OpenDatePickerButton>
          </DateFieldWrapper>
        </div>
        <div className="flex-grow-1">
          <p className="ml-4">
            {label && `${label} `}Time
            {autocompleted && <span className="ml-1 text-black-50">(autocompleted)</span>}
          </p>
          <div className="d-flex">
            <Select
              className="flex-grow-1 mx-4"
              options={getHourOptions()}
              value={{
                label: DateTime.fromISO(input.value).hour,
                value: DateTime.fromISO(input.value).hour,
              }}
              onChange={handleOnChangeHour}
            />
            <Select
              className="flex-grow-1"
              options={getMinuteOptions()}
              value={{
                label: DateTime.fromISO(input.value).minute,
                value: DateTime.fromISO(input.value).minute,
              }}
              onChange={handleOnChangeMinute}
            />
          </div>
        </div>
      </div>

      {/* DateTimeModal */}
      <DateModal
        isOpen={dateTimeModalIsOpen}
        value={input.value}
        onChange={handleOnChangeDate}
        onToggle={handleOnToggleDateModal}
      />
    </>
  );
};

export default DateTimeField;
