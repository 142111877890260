import React from 'react';
import { Link } from 'wouter';
import { useQuery } from '@apollo/client';
import { CUSTOMERS_QUERY } from './query';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-duotone-svg-icons/faPlus';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons/faUsers';
import CurrentPagePortal from '../CurrentPagePortal';
import Table from '../Table';
import SimpleButton from '../SimpleButton';

const Customers = () => {
  const query = useQuery(CUSTOMERS_QUERY, { fetchPolicy: 'network-only' });
  return (
    <>
      <CurrentPagePortal>
        <h2>
          <FontAwesomeIcon icon={faUsers} fixedWidth className="mr-2" />
          Customers
        </h2>
      </CurrentPagePortal>
      <Container>
        <div className="d-flex border-bottom mb-2">
          <Link to="/customers/create">
            <SimpleButton icon={faPlus}>
              Create customer
            </SimpleButton>
          </Link>
        </div>
        <Table.Wrapper className="mt-5">
          <Table.Head>
            <Table.HeadRow>
              <Table.HeadCell>
                ID
              </Table.HeadCell>
              <Table.HeadCell>
                Name
              </Table.HeadCell>
            </Table.HeadRow>
          </Table.Head>
          <Table.Body>
            {query.data?.customers.map((customer: any) => (
              <Link key={customer.id} to={`/customers/${customer.id}`}>
                <Table.Row>
                  <Table.Cell>
                    {customer.id}
                  </Table.Cell>
                  <Table.Cell>
                    {customer.name}
                  </Table.Cell>
                </Table.Row>
              </Link>
            ))}
          </Table.Body>
        </Table.Wrapper>
      </Container>
    </>
  );
};

export default Customers;
