import React from 'react';
import msalClient from 'lib/auth/msalClient';
import { Route, Redirect, RouteProps } from 'wouter';
import { DefaultParams } from 'wouter/matcher';

const ProtectedRoute = <T extends DefaultParams = DefaultParams>(props: RouteProps<T>) => {
  return msalClient.getAccount() === null
    ? <Redirect href="/login" />
    : <Route {...props} />
};

export default ProtectedRoute;
