import React from 'react';
import CurrentPagePortal from '../CurrentPagePortal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-duotone-svg-icons/faClock';
import { Container, Row, Col } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { JOBS_QUERY } from './query';
import JobList from '../JobList';

const Jobs = () => {
  const query = useQuery(JOBS_QUERY);
  return (
    <>
      <CurrentPagePortal>
        <h2>
          <FontAwesomeIcon icon={faClock} fixedWidth className="mr-2" />
          Jobs
        </h2>
      </CurrentPagePortal>
      <Container className="mt-4">
        {query.data && (
          <div>
            <Row>
              <Col>
                <JobList
                  status="OPEN"
                  jobs={query.data.openJobs}
                  count={query.data.jobStatistics.open}
                />
              </Col>
              <Col>
                <JobList
                  status="COMPLETED"
                  jobs={query.data.completedJobs}
                  count={query.data.jobStatistics.completed}
                />
              </Col>
              <Col>
                <JobList
                  status="CLOSED"
                  jobs={query.data.closedJobs}
                  count={query.data.jobStatistics.closed}
                />
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </>
  );
};

export default Jobs;
