import styled from 'styled-components';

export const SidebarWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 175px;
  width: 225px;
`;

export const SidebarIconCategoryHeader = styled.div`
    padding: 0.25rem;
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-left: 1rem;
`;

export const SidebarIcon = styled.a`
  display: flex;
  color: ${({ theme }) => theme.color.primary};
  background-color: transparent;
  border: 2px solid transparent;
  cursor: pointer;
  width: 100%;
  padding: 0.25rem 1rem;
  
  svg {
    height: 24px;
    margin-right: 0.5rem;
  }
  
  p {
    margin-bottom: 0;
    user-select: none;
  }
  
  &:hover {
    color: ${({ theme }) => theme.color.primary};
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:focus {
    //border: 2px solid ${({ theme }) => theme.color.primary};
  }
`;
