import styled from 'styled-components';
import { NavbarLinkProps, NotificationPanelWrapperProps } from './types';

export const TopbarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.primary};
  width: 100%;
  height: 40px;
`;

export const NavbarWrapper = styled.div`

`;

export const NavbarLink = styled.a<NavbarLinkProps>`
  user-select: none;
  height: 40px;
  cursor: pointer;
  font-size: 1rem;
  color: white;
  line-height: 40px;
  padding: 0 1rem;
  background-color: ${({ active }) => (active ? 'rgba(35, 255, 255, 0.2)' : 'transparent')};

  &:hover {
    color: white;
    background-color: rgba(35, 255, 255, 0.2);
    text-decoration: none;     
  }
  
  svg {
    color: white;
  }
`;

export const NavbarSearchBar = styled.div`
  position: relative;
  
  svg {
    position: absolute;
    left: 0.5rem;
    top: 57.5%;
    transform: translateY(-50%);
  }
`;

export const NavbarSearchBarInput = styled.input`
  padding-left: 2.5rem;
  height: 26px;
  margin-top: 7px;
  background-color: #f4f4f4;
  color: black;
  border: none;
  border-radius: 0.1rem;
  font-size: 0.8rem;
  
  &:focus {
    background-color: white;
    outline: none;
  }
  
  &::placeholder {
    color: ${({ theme }) => theme.color.primary}
  }
`;

export const NotificationBellWrapper = styled.div`
  position: relative;
  
  div {
    position: absolute;
    top: 8px;  
    right: 2px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.danger};
  }
`;
