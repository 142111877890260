import styled from 'styled-components';
import { InputLabelProps } from './types';

export const InputStyled = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 2px solid rgba(0, 0, 0, 0.1);
  color: black !important;
  
  &:hover {
    border: 2px solid rgba(0, 0, 0, 0.2);      
  }
  
  &:focus {
    outline: none;
    border: 2px solid ${({ theme }) => theme.color.primary};  
  }
  
  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus, 
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
  }
`;

export const InputLabel = styled.label<InputLabelProps>`
  position: absolute;
  top: -14px;
  left: 1rem;
  padding: 0 5px;
  color: ${({ theme, hasError }) => (hasError ? theme.color.danger : 'rgba(0, 0, 0, 0.75)')};
  background-color: white;
  display: block;
  text-transform: capitalize;
  opacity: ${({ hasValue, hasError }) => (hasError || hasValue ? 1 : 0)};
  transition: opacity 300ms;
  z-index: 1;
`;
