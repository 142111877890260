import { gql } from '@apollo/client';

export const FORM_QUERY = gql`
  query($customerId: ID) {
    customer(customerId: $customerId) {
      id
      name
      employees {
        id
        name
      }
    }
    incidentTypes {
      id
      name
    }
  }
`;

export const CREATE_EMPLOYEE_MUTATION = gql`
  mutation($customerId: ID, $data: EmployeeInputType) {
    createEmployee(customerId: $customerId, data: $data) {
      newEmployee {
        id
        name
      }
      employees {
        id
        name
      }
    }
  } 
`;

export const CREATE_INCIDENT_TYPE_MUTATION = gql`
  mutation($data: IncidentTypeInputType) {
    createIncidentType(data: $data) {
      newIncidentType {
        id
        name
      }
      incidentTypes {
        id
        name
      }
    }
  }
`;

export const CREATE_JOB_MUTATION = gql`
  mutation($customerId: ID, $data: JobInputType) {
    createJob(customerId: $customerId, data: $data) {
      job {
        id
      } 
    }
  }
`;
