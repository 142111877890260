import { gql } from '@apollo/client';

export const JOB_QUERY = gql`
  query($id: ID) {
    job(jobId: $id) {
      id
      duration
      status
      dateTimeSubmitted
      customer {
        id
        name
      }
      raisedBy {
        name
      }
      incidentType {
        name
      }
      furtherActionRequests {
        id
        user {
          id
          name
        }
      }
      timelineItems {
        id
        itemType
        itemTypeLabel
        dateTime
        furtherActionCompleted
        furtherActionRequest {
          completed
          user {
            id
            name          
          }
        }
        furtherAction {
          duration
          dateTimeSubmitted
          incidentType {
            name
          }
          user {
            id
            name
          }
        }
        comment {
          id
          body
        }
        user {
          id
          name
        }
      }
    }
  }
`;

export const SUBMIT_FURTHER_ACTION_FORM_QUERY = gql`
  query($jobId: ID) {
    furtherActionRequests(jobId: $jobId) {
      id
      notes
      user {
        id
        name
      }
    }
    incidentTypes {
      id
      name
    }
  }
`;

export const CREATE_COMMENT_MUTATION = gql`
  mutation($jobId: ID, $data: CommentInputType) {
    createComment(jobId: $jobId, data: $data) {
      timelineItem {
        id
        itemType
        itemTypeLabel
        dateTime
        user {
          id
          name
        }
        comment {
          id
          body
        }
      }
    }
  }
`;

export const CREATE_FURTHER_ACTION_MUTATION = gql`
  mutation($jobId: ID, $data: FurtherActionInputType) {
    createFurtherAction(jobId: $jobId, data: $data) {
      timelineItems {
        id
        itemType
        itemTypeLabel
        dateTime
        user {
          id
          name
        }
        furtherActionRequest {
          completed
          user {
            id
            name
          }
        }
        furtherAction {
          id
          duration
          dateTimeSubmitted
          incidentType {
            id
            name
          }
          user {
            id
            name
          }
        }
        comment {
          id
          body
        }
      }
    }
  }
`;

export const CREATE_FURTHER_ACTION_REQUEST_MUTATION = gql`
  mutation($jobId: ID, $data: FurtherActionRequestInputType) {
    createFurtherActionRequest(jobId: $jobId, data: $data) {
      job {
        status
      }
      timelineItems {
        id
        itemType
        itemTypeLabel
        dateTime
        user {
          id
          name
        }
        furtherActionRequest {
          completed
          user {
            id
            name
          }
        }
        furtherAction {
          id
          duration
          dateTimeSubmitted
          incidentType {
            id
            name
          }
          user {
            id
            name
          }
        }
        comment {
          id
          body
        }
      }
    }
  }
`;
