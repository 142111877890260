import React from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'wouter';
import { Container, Row, Col } from 'reactstrap';
import { faPlus } from '@fortawesome/pro-duotone-svg-icons/faPlus';
import { Icon } from './styled';
import { CUSTOMERS_QUERY } from './query';
import SimpleButton from '../SimpleButton';
import CurrentPagePortal from '../CurrentPagePortal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons/faUsers';

const Dashboard = () => {
  const query = useQuery(CUSTOMERS_QUERY, { fetchPolicy: 'network-only' });
  return (
    <>
      <CurrentPagePortal>
        <h2>
          Portal Home
        </h2>
      </CurrentPagePortal>
      <Container>
        {query.data?.customers.map((customer: any) => (
          <div>
            <Link to={`/customers/${customer.id}`}>
              {customer.name}
            </Link>
          </div>
        ))}
      </Container>
    </>
  );
};

export default Dashboard;

