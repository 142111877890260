import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons/faCheck';
import { CheckboxWrapper, InputStyled, CheckboxButton } from './styled';

const Checkbox = ({ label, ...props } : any) => (
  <CheckboxWrapper>
    <InputStyled
      type="checkbox"
      {...props}
    />
    <CheckboxButton className="rounded" checked={props.checked}>
      <FontAwesomeIcon icon={faCheck} />
    </CheckboxButton>
    {label && (
      <span className="pl-2" style={{ marginLeft: 20, userSelect: 'none', lineHeight: '20px' }}>
        {label}
      </span>
    )}
  </CheckboxWrapper>
);

export default Checkbox;
