import styled from 'styled-components';

export const DateFieldWrapper = styled.button`
  width: 100%;
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0.1);
  background-color: transparent;
  height: 50px;
  padding: 0;
  
  p {
    margin-left: 8px;
    line-height: 44px;
  }
  
  &:hover {
    border: 2px solid rgba(0, 0, 0, 0.2);    
  }
  
  &:focus {
    outline: none;    
  }
  
  &:active {
    outline: none;
  }
`;

export const OpenDatePickerButton = styled.div`
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  width: 46px;
  height: 32px;
  margin: 7px 0;
  
  svg {
    height: 20px;
    width: 20px;
    margin: 6px 0;
  }
`;
