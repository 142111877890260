import React from 'react';
import classNames from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import { InputStyled, InputLabel } from './styled';

const Input = ({ input, meta, className, ...rest }: FieldRenderProps<string>) => {
  const hasError = !!meta.touched && !!meta.error;
  return (
    <div className={classNames(className, 'position-relative')}>
      <InputLabel hasValue={!!input.value} hasError={hasError}>
        {hasError ? meta.error : input.name}
      </InputLabel>
      <InputStyled className="rounded" {...input} {...rest} />
    </div>
  );
};

export default Input;
