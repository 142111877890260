import React from 'react';
import { useMutation } from '@apollo/client';
import { Form, Field } from 'react-final-form';
import { faPlus } from '@fortawesome/pro-duotone-svg-icons/faPlus';
import { Container } from 'reactstrap';
import Input from '../Input';
import CustomerEmployeeField from '../CustomerEmployeeField';
import SimpleButton from '../SimpleButton';
import { CREATE_CUSTOMER_MUTATION } from './query';
import CurrentPagePortal from '../CurrentPagePortal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons/faUsers';
import { useLocation } from 'wouter';

const CustomersCreate = () => {
  const [createCustomer, { loading: creatingCustomer }] = useMutation(CREATE_CUSTOMER_MUTATION);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setLocation] = useLocation();
  const handleOnSubmit = (values: any) => {
    createCustomer({ variables: { data: values } })
      .then((response) => {
        setLocation(`/customer/${response.data.createCustomer.customer.id}`);
      });
  };

  return (
    <>
      <CurrentPagePortal>
        <h2>
          <FontAwesomeIcon icon={faUsers} fixedWidth className="mr-2" />
          Create Customer
        </h2>
      </CurrentPagePortal>
      <Container>
        <Form
          onSubmit={handleOnSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                className="mt-5"
                name="name"
                placeholder="Name"
                component={Input}
              />
              <Field
                className="mt-5 rounded p-4 pb-5"
                name="employees"
                component={CustomerEmployeeField}
              />
              <div className="d-flex justify-content-end mt-5">
                <SimpleButton hoverStyles icon={faPlus} loading={creatingCustomer}>
                  Create
                </SimpleButton>
              </div>
            </form>
          )}
        />
      </Container>
    </>
  );
};

export default CustomersCreate;
