import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CREATE_COMMENT_MUTATION,
  CREATE_FURTHER_ACTION_MUTATION,
  SUBMIT_FURTHER_ACTION_FORM_QUERY,
  CREATE_FURTHER_ACTION_REQUEST_MUTATION,
  JOB_QUERY,
} from './query';
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons/faUserCircle';
import { faComment } from '@fortawesome/pro-duotone-svg-icons/faComment';
import { faCommentPlus } from '@fortawesome/pro-duotone-svg-icons/faCommentPlus';
import { faClock } from '@fortawesome/pro-duotone-svg-icons/faClock';
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons/faCheck';
import {
  JobHeader,
  FurtherActionRequestStatus,
  TimeLineItemWrapper,
  TimelineDateTime,
  TimeLineItemIcon,
  TimeLineItemHeader,
  TimeLineContentWrapper,
} from './styled';
import { DateTime } from 'luxon';
import { TextareaStyled } from '../TextareaField/styled';
import SimpleButton from '../SimpleButton';
import { Form, Field } from 'react-final-form';
import SelectField from '../SelectField';
import CreatableSelectField from '../CreatableSelectField';
import JobTimeField from '../JobTimeField';
import { CREATE_INCIDENT_TYPE_MUTATION } from '../JobsCreate/query';
import DateTimeField from '../DateTimeField';
import CurrentPagePortal from '../CurrentPagePortal';
import FurtherActionRequestField from '../FutherActionRequestField';
import JobStatus from '../JobStatus';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons/faUsers';

const TimelineBlockHeader = ({ itemTypeLabel, dateTime, user, ...rest }: any) => (
  <TimeLineItemHeader className="d-flex justify-content-between rounded-top" {...rest}>
    <div>
      <strong>
        {user.name}
      </strong>
      <span>
        {' '}{itemTypeLabel} this job
      </span>
      <span>
        {' '}{DateTime.fromISO(dateTime).toRelativeCalendar()}
      </span>
    </div>
  </TimeLineItemHeader>
);

const TimelineCreateBlock = (props: any) => {
  const { id, dateTime, penultimate, job } = props;
  return (
    <TimeLineItemWrapper key={id} last={false} penultimate={penultimate}>
      <TimelineDateTime>
        {DateTime.fromISO(dateTime).toLocaleString(DateTime.DATETIME_MED)}
      </TimelineDateTime>
      <TimeLineItemIcon icon={faUserCircle} />
      <TimelineBlockHeader {...props} />
      <TimeLineContentWrapper className="rounded-bottom border border-top-0">
        <table style={{ borderCollapse: 'separate', borderSpacing: '0 5px' }}>
          <tbody>
            <tr>
              <td className="text-nowrap">Raised by</td>
              <td><strong>{job.raisedBy.name}</strong></td>
            </tr>
            <tr className="py-5">
              <td className="text-nowrap align-top pr-5">Incident type</td>
              <td><strong>{job.incidentType.name}</strong></td>
            </tr>
            <tr>
              <td>Duration</td>
              <td><strong>{job.duration} minutes</strong></td>
            </tr>
          </tbody>
        </table>
      </TimeLineContentWrapper>
    </TimeLineItemWrapper>
  );
};

const TimelineCommentBlock = (props: any) => {
  const { id, dateTime, penultimate, comment } = props;
  return (
    <TimeLineItemWrapper key={id} last={false} penultimate={penultimate}>
      <TimelineDateTime>
        {DateTime.fromISO(dateTime).toLocaleString(DateTime.DATETIME_MED)}
      </TimelineDateTime>
      <TimeLineItemIcon icon={faUserCircle} />
      <TimelineBlockHeader {...props} />
      <TimeLineContentWrapper className="d-flex rounded-bottom border border-top-0">
        {comment.body}
      </TimeLineContentWrapper>
    </TimeLineItemWrapper>
  );
};

const TimelineRequestFurtherActionBlock = (props: any) => {
  const { id, user, dateTime, penultimate, furtherActionRequest } = props;
  return (
    <TimeLineItemWrapper key={id} last={false} penultimate={penultimate}>
      <TimelineDateTime>
        {DateTime.fromISO(dateTime).toLocaleString(DateTime.DATETIME_MED)}
      </TimelineDateTime>
      <TimeLineItemIcon icon={faUserCircle} />
      <TimeLineContentWrapper>
        <div className="d-flex justify-content-between">
          <div>
            <strong>{user.name}</strong> requested further action from <strong>{furtherActionRequest.user.name}</strong>
            <span>
              {' '}{DateTime.fromISO(dateTime).toRelativeCalendar()}
            </span>
          </div>
          <div>
            {furtherActionRequest.completed ? (
              <FurtherActionRequestStatus color="primary">
                <FontAwesomeIcon icon={faCheck} fixedWidth /> Completed
              </FurtherActionRequestStatus>
            ) : (
              <FurtherActionRequestStatus color="danger">
                <FontAwesomeIcon icon={faTimes} fixedWidth /> Awaiting completion
              </FurtherActionRequestStatus>
            )}
          </div>
        </div>
      </TimeLineContentWrapper>
    </TimeLineItemWrapper>
  );
};

const TimelineCompleteFurtherActionBlock = (props: any) => {
  const { id, dateTime, furtherAction, penultimate } = props;
  return (
    <TimeLineItemWrapper key={id} last={false} penultimate={penultimate}>
      <TimelineDateTime>
        {DateTime.fromISO(dateTime).toLocaleString(DateTime.DATETIME_MED)}
      </TimelineDateTime>
      <TimeLineItemIcon icon={faUserCircle} />
      <TimelineBlockHeader style={{ backgroundColor: 'green', color: 'white' }} {...props} />
      <TimeLineContentWrapper className="rounded-bottom border border-top-0">
        <div className="d-flex">
          <div className="mr-3">
            <p>Requested by</p>
            <p>Incident type</p>
            <p className="mb-0">Duration</p>
          </div>
          <div>
            <p><strong>{furtherAction.user.name}</strong></p>
            <p><strong>{furtherAction.incidentType.name}</strong></p>
            <p className="mb-0"><strong>{furtherAction.duration} minutes</strong></p>
          </div>
        </div>
      </TimeLineContentWrapper>
    </TimeLineItemWrapper>
  );
};

const TimelineCompleteJobActionBlock = ({ id, dateTime, user, penultimate }: any) => (
  <TimeLineItemWrapper key={id} last={false} penultimate={penultimate}>
    <TimelineDateTime>
      {DateTime.fromISO(dateTime).toLocaleString(DateTime.DATETIME_MED)}
    </TimelineDateTime>
    <TimeLineItemIcon icon={faUserCircle} />
    <TimeLineContentWrapper className="text-white rounded" style={{ backgroundColor: 'green' }}>
      <div className="d-flex justify-content-between">
        <div>
          <strong>{user.name}</strong> completed this job
          <span>
              {' '}{DateTime.fromISO(dateTime).toRelativeCalendar()}
            </span>
        </div>
        <div>
          <FontAwesomeIcon icon={faCheck} fixedWidth /> Completed
        </div>
      </div>
    </TimeLineContentWrapper>
  </TimeLineItemWrapper>
);

const TimelineItem = (props: any) => {
  const { itemType } = props;
  switch (itemType) {
    case 'CREATE':
      return <TimelineCreateBlock {...props} />;
    case 'COMPLETE':
      return <TimelineCompleteJobActionBlock {...props} />;
    case 'COMMENT':
      return <TimelineCommentBlock {...props} />;
    case 'REQUEST_FURTHER_ACTION':
      return <TimelineRequestFurtherActionBlock {...props} />;
    case 'COMPLETE_FURTHER_ACTION':
      return <TimelineCompleteFurtherActionBlock {...props} />;
    default:
      return null;
  }
};

const JobDetail = ({ params }: any) => {
  const [job, setJob] = useState();
  const [timelineItems, setTimelineItems] = useState<any[]>([]);
  const query = useQuery(JOB_QUERY,{
    variables: { id: params.id },
    onCompleted: (data) => {
      setJob(data.job);
      setTimelineItems(data.job.timelineItems);
    },
  });

  const [comment, setComment] = useState('');
  const [createComment, { loading: creatingComment }] = useMutation(CREATE_COMMENT_MUTATION);
  const handleCreateComment = () => {
    if (!comment) return;
    createComment({ variables: { jobId: job.id, data: { body: comment } } })
      .then((response) => {
        setTimelineItems((prevTimelineItems: any) => ([
          ...prevTimelineItems,
          response.data.createComment.timelineItem,
        ]));
        setComment('');
      });
  };

  const renderCreateCommentActionBlock = () => (
    <>
      <TimeLineItemIcon icon={faCommentPlus} />
      <TextareaStyled
        className="rounded-bottom border-top-0"
        style={{ borderWidth: 1 }}
        value={comment}
        onChange={(event) => setComment(event.target.value)}
        placeholder="Type your comment here..."
      />
      <div className="d-flex justify-content-end">
        {job.status === 'OPEN' && (
          <SimpleButton icon={faTimes} color="danger">
            Close job
          </SimpleButton>
        )}
        <SimpleButton
          icon={faComment}
          disabled={!comment}
          loading={creatingComment}
          onClick={handleCreateComment}
        >
          Send comment
        </SimpleButton>
      </div>
    </>
  );

  const [actionTab, setActionTab] = useState(1);
  const [{ furtherActionRequests, incidentTypes }, setSubmitFurtherActionFormData] = useState<any>({
    furtherActionRequests: [],
    incidentTypes: [],
  });
  const [getSubmitFurtherActionFormData, { loading: gettingSubmitFurtherActionFormData }] = useLazyQuery(
    SUBMIT_FURTHER_ACTION_FORM_QUERY,
    {
      onCompleted: (data) => setSubmitFurtherActionFormData(data),
    },
  );
  useEffect(() => {
    if (actionTab === 2) {
      getSubmitFurtherActionFormData({ variables: { jobId: job.id } });
    }
    // TODO: Research this here
  }, [actionTab, getSubmitFurtherActionFormData]);
  const [createIncidentType, { loading: creatingIncidentType }] = useMutation(CREATE_INCIDENT_TYPE_MUTATION);
  const handleOnCreateIncidentType = (name: string) => {
    return createIncidentType({ variables: { data: { name } } })
      .then((response) => {
        setSubmitFurtherActionFormData((prevFormData: any) => ({
          ...prevFormData,
          incidentTypes: response.data.createIncidentType.incidentTypes,
        }))
        return response.data.createIncidentType.newIncidentType;
      });
  };

  const [createFurtherAction, { loading: creatingFurtherAction }] = useMutation(CREATE_FURTHER_ACTION_MUTATION);
  const handleOnSubmit = (values: any) => {
    createFurtherAction({ variables: { jobId: job.id, data: values } })
      .then((response) => {
        setTimelineItems(response.data.createFurtherAction.timelineItems);
        setActionTab(1);
      });
  };

  const [autoCompletedField, setAutoCompletedField] = useState('');
  const renderSubmitFurtherActionBlock = () => (
    <div className="border border-top-0 rounded">
      <TimeLineItemIcon icon={faClock} />
      <div className="p-3">
        <Form
          initialValues={{
            dateTimeSubmitted: DateTime.local().toISO(),
            dateTimeCompleted: DateTime.local().toISO(),
          }}
          mutators={{
            setDuration: (args, state, utils) => {
              const [dateTimeSubmitted, dateTimeCompleted] = args;
              if (dateTimeSubmitted) {
                const { dateTimeCompleted } = state.formState.values as any;
                const duration = Math.floor(DateTime.fromISO(dateTimeCompleted).diff(DateTime.fromISO(dateTimeSubmitted), ['minutes']).minutes)
                utils.changeValue(state, 'duration', () => duration);
              }
              if (dateTimeCompleted) {
                const { dateTimeSubmitted } = state.formState.values as any;
                const duration = Math.floor(DateTime.fromISO(dateTimeCompleted).diff(DateTime.fromISO(dateTimeSubmitted), ['minutes']).minutes)
                utils.changeValue(state, 'duration', () => duration);
              }
              setAutoCompletedField('duration');
            },
            setDateTimeCompleted: (args, state, utils) => {
              const [duration] = args;
              const { dateTimeSubmitted } = state.formState.values as any;
              const dateTimeCompleted = DateTime.fromISO(dateTimeSubmitted).plus({ minutes: duration || 0 }).toISO();
              utils.changeValue(state, 'dateTimeCompleted', () => dateTimeCompleted);
              setAutoCompletedField('dateTimeCompleted')
            },
          }}
          keepDirtyOnReinitialize
          onSubmit={handleOnSubmit}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <p>Completing Further Action Request</p>
                <Field
                  name="furtherActionRequest"
                  loading={gettingSubmitFurtherActionFormData}
                  options={furtherActionRequests.map((furtherActionRequest: any) => ({
                    label: furtherActionRequest.notes,
                    value: furtherActionRequest.id,
                  }))}
                  component={SelectField}
                />
              </div>
              <div className="mb-3">
                <Field
                  label="Start"
                  name="dateTimeSubmitted"
                  component={DateTimeField}
                  parse={(value) => {
                    form.mutators.setDuration(value, null);
                    return value;
                  }}
                />
              </div>
              <div className="mb-3">
                <p>Incident Type</p>
                <Field
                  name="incidentType"
                  loading={gettingSubmitFurtherActionFormData || creatingIncidentType}
                  options={incidentTypes
                    .map((incidentType: any) => ({ label: incidentType.name, value: incidentType.id }))}
                  component={CreatableSelectField}
                  onCreateOption={handleOnCreateIncidentType}
                />
              </div>
              <div className="mb-3">
                <p>
                  Duration
                  {autoCompletedField === 'duration' && <span className="ml-1 text-black-50">(autocompleted)</span>}
                </p>
                <Field
                  name="duration"
                  component={JobTimeField}
                  parse={(value) => {
                    form.mutators.setDateTimeCompleted(value);
                    return value;
                  }}
                />
              </div>
              <div className="mb-3">
                <Field
                  name="dateTimeCompleted"
                  autocompleted={autoCompletedField === 'dateTimeCompleted'}
                  label="End"
                  parse={(value) => {
                    form.mutators.setDuration(null, value);
                    return value;
                  }}
                  component={DateTimeField}
                />
              </div>
              <div className="d-flex justify-content-end">
                <SimpleButton icon={faCheck} loading={creatingFurtherAction}>
                  Submit
                </SimpleButton>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );

  const [createFurtherActionRequest, { loading: creatingFurtherActionRequest }] = useMutation(
    CREATE_FURTHER_ACTION_REQUEST_MUTATION);
  const handleOnRequestFurtherAction = (values: any) => {
    createFurtherActionRequest({ variables: { jobId: job.id, data: values.furtherActionRequest } })
      .then((response) => {
        setJob((prevJob: any) => ({ ...prevJob, status: response.data.createFurtherActionRequest.job.status }));
        setTimelineItems(response.data.createFurtherActionRequest.timelineItems);
        setActionTab(1);
      });
  };

  const renderRequestFurtherActionBlock = () => (
    <div className="border border-top-0 rounded">
      <TimeLineItemIcon icon={faClock} />
      <div className="p-3">
        <Form
          initialValues={{}}
          onSubmit={handleOnRequestFurtherAction}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="furtherActionRequest"
                optional={false}
                component={FurtherActionRequestField}
              />
              <div className="d-flex justify-content-end">
                <SimpleButton icon={faCheck} loading={creatingFurtherActionRequest}>
                  Submit
                </SimpleButton>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );

  const renderActionBlock = () => {
    const renderActionBlockContents = () => {
      switch (actionTab) {
        case 1:
          return renderCreateCommentActionBlock();
        case 2:
          return renderSubmitFurtherActionBlock();
        case 3:
          return renderRequestFurtherActionBlock();
        default:
          return null;
      }
    };

    return (
      <TimeLineItemWrapper last penultimate={false}>
        <TimeLineItemHeader className="d-flex rounded-top">
          {actionTab === 1 ? (
            <div style={{ padding: '0.3rem 0.5rem' }}>
              <strong>
                Add comment
              </strong>
            </div>
          ) : (
            <SimpleButton hoverStyles onClick={() => setActionTab(1)}>
              Add comment
            </SimpleButton>
          )}
          {actionTab === 2 ? (
            <div style={{ padding: '0.3rem 0.5rem' }}>
              <strong>
                Submit further action
              </strong>
            </div>
          ) : (
            <SimpleButton
              hoverStyles
              onClick={() => setActionTab(2)}
              disabled={job.furtherActionRequests.length === 0}
            >
              Submit further action
            </SimpleButton>
          )}
          {actionTab === 3 ? (
            <div style={{ padding: '0.3rem 0.5rem' }}>
              <strong>
                Request further action
              </strong>
            </div>
          ) : (
            <SimpleButton hoverStyles onClick={() => setActionTab(3)}>
              Request further action
            </SimpleButton>
          )}
        </TimeLineItemHeader>
        {renderActionBlockContents()}
      </TimeLineItemWrapper>
    );
  };

  if (query.loading || !job) {
    return (
      <div>

      </div>
    );
  }

  return (
    <>
      <CurrentPagePortal>
        <h2>
          <FontAwesomeIcon icon={faClock} fixedWidth className="mr-2" />
          <span className="text-capitalize">{job.status.toLowerCase()}</span>
          {' '}job <span className="text-black-50">| ID {job.id}</span>
        </h2>
      </CurrentPagePortal>
      <Container className="mt-4">
        <Row>
          <Col xl={8} xs={9} className="offset-xl-2">
            <JobHeader className="pb-5 ml-2">
              <TimeLineItemIcon icon={faUsers} />
              <div className="d-flex justify-content-between">
                <div>
                  <h3><strong>{job.customer.name}</strong></h3>
                  <p>{job.incidentType.name}</p>
                </div>
                <div>
                  <h3 className="text-black-50 text-nowrap">Job no: <strong>{job.id}</strong></h3>
                  <div className="d-flex justify-content-end">
                    <JobStatus status={job.status} />
                  </div>
                </div>
              </div>
            </JobHeader>
            <div>
              <div className="ml-2">
                {timelineItems.map((timelineItem: any, index: number) => (
                  <TimelineItem
                    key={timelineItem.id}
                    last={index === timelineItems.length}
                    penultimate={index === timelineItems.length - 1}
                    job={job}
                    {...timelineItem}
                  />
                ))}
                {renderActionBlock()}
              </div>
            </div>
          </Col>
          {/*<Col>*/}
          {/*  <div className="pl-4 border-left h-100">*/}
          {/*    <h4>Further actions</h4>*/}
          {/*    <hr />*/}
          {/*    {job.furtherActionRequests.map((furtherActionRequest: any) => (*/}
          {/*      <div>*/}
          {/*        {furtherActionRequest.user.name}*/}
          {/*      </div>*/}
          {/*    ))}*/}
          {/*  </div>*/}
          {/*</Col>*/}
        </Row>
      </Container>
    </>
  );
};

export default JobDetail;
