import React from 'react';
import { components } from 'react-select';
import Creatable from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons/faChevronDown';
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes';
import theme from '@cnsuk/cnsuk-theme';

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <FontAwesomeIcon icon={faChevronDown} fixedWidth />
  </components.DropdownIndicator>
);

const ClearIndicator = (props: any) => (
  <components.ClearIndicator {...props}>
    <FontAwesomeIcon icon={faTimes} fixedWidth />
  </components.ClearIndicator>
);

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderWidth: state.isFocused ? 2 : 2,
    borderColor: state.isFocused ? state.theme.colors.primary : 'rgba(0, 0, 0, 0.1)',
    boxShadow: 'none',
  }),
};

const CreatableSelect = (props: any) => (
  <Creatable
    components={{ DropdownIndicator, ClearIndicator }}
    styles={customStyles}
    theme={(defaultTheme) => ({
      ...defaultTheme,
      spacing: {
        ...defaultTheme.spacing,
        controlHeight: 50,
      },
      colors: {
        ...defaultTheme.colors,
        primary: theme.color.primary.hex(),
      },
    })}
    {...props}
  />
);

export default CreatableSelect;
