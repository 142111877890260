import React, { useState } from 'react';
import { DateTime } from 'luxon';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-duotone-svg-icons/faChevronLeft';
import { faChevronDoubleLeft } from '@fortawesome/pro-duotone-svg-icons/faChevronDoubleLeft';
import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons/faChevronRight';
import { faChevronDoubleRight } from '@fortawesome/pro-duotone-svg-icons/faChevronDoubleRight';
import SimpleButton from '../SimpleButton';
import { ColumnHeader, Row, Cell } from './styled';

const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

type DatePickerProps = {
  value: string;
  onChange: (dateString: string) => void;
};

const DatePicker = ({ value, onChange }: DatePickerProps) => {
  const [date, setDate] = useState(DateTime.local());

  const previousMonday = date.startOf('month').startOf('week');

  const handleChangeView = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const { value: durationDelta } = event.currentTarget;
    setDate(date.plus(JSON.parse(durationDelta)));
  };

  const handleCellOnClick = (cellDate: DateTime) => (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    onChange(cellDate.toString());
  };

  const renderCell = (rowIndex: number, cellIndex: number) => {
    const cellDate = previousMonday.plus({ day: cellIndex + (7 * rowIndex) });
    const dayOfMonth = cellDate.day;
    const evenMonth = cellDate.month % 2 === 0;
    const today = cellDate.startOf('day').equals(DateTime.local().startOf('day'));
    const selected = DateTime.fromISO(value).startOf('day').equals(cellDate.startOf('day'));
    return (
      <Cell
        key={`${rowIndex}:${cellIndex}`}
        darker={evenMonth}
        outline={today}
        selected={selected}
        onClick={handleCellOnClick(cellDate)}
      >
        <div className="p-2">
          {dayOfMonth}
          {dayOfMonth === 1 && (
            <span>/{cellDate.monthShort}</span>
          )}
        </div>
      </Cell>
    );
  };

  const renderRow = (rowIndex: number) => (
    <Row className="d-flex justify-content-around">
      {Array.from({ length: 7 }, (_, cellIndex) => renderCell(rowIndex, cellIndex))}
    </Row>
  );

  return (
    <div>
      <div className="d-flex justify-content-center mb-3">
        <SimpleButton hoverStyles onClick={handleChangeView} value={JSON.stringify({ year: -1 })}>
          <FontAwesomeIcon icon={faChevronDoubleLeft} fixedWidth />
        </SimpleButton>
        <SimpleButton onClick={handleChangeView} value={JSON.stringify({ month: -1 })}>
          <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
        </SimpleButton>
        <h3 className="mx-3">{date.monthShort}, {date.year}</h3>
        <SimpleButton onClick={handleChangeView} value={JSON.stringify({ month: 1 })}>
          <FontAwesomeIcon icon={faChevronRight} fixedWidth />
        </SimpleButton>
        <SimpleButton onClick={handleChangeView} value={JSON.stringify({ year: 1 })}>
          <FontAwesomeIcon icon={faChevronDoubleRight} fixedWidth />
        </SimpleButton>
      </div>
      <div className="d-flex justify-content-around border-bottom">
        {days.map((day) => (
          <ColumnHeader className="text-capitalize">{day}</ColumnHeader>
        ))}
      </div>
      {Array.from({ length: 5 }, (_, rowIndex) => renderRow(rowIndex))}
    </div>
  );
};

export default DatePicker;
