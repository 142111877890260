import styled from 'styled-components';
import { Color } from 'chroma-js';
import { ColorScheme, Theme } from 'lib/types';

type ButtonWithRemovedStylesProps = {
  hoverStyles?: boolean;
  disabled?: boolean;
  backgroundColor?: string;
  color?: keyof ColorScheme | string;
};

const getColor = (color: keyof ColorScheme | string, theme: Theme): Color | string => {
  if (color in theme.color) return theme.color[color as keyof ColorScheme];
  return color;
};

export const ButtonWithRemovedStyles = styled.button<ButtonWithRemovedStylesProps>`
  outline: none;
  border: none;
  border-radius: ${({ hoverStyles }) => (hoverStyles ? '0.3rem' : 0)};
  padding: ${({ hoverStyles }) => (hoverStyles ? '0.3rem 0.5rem' : 0)};
  margin: 0;
  background-color: ${({ backgroundColor }) => (backgroundColor || 'transparent')};
  ${({ theme, color }) => (color ? `color: ${getColor(color, theme)}` : '' )};
  ${({ hoverStyles }) => {
    if (hoverStyles) {
      return 'transition: background-color 200ms';
    }
    return '';
  }};

  &:hover {
    cursor: pointer;
    text-decoration: ${({ hoverStyles }) => (hoverStyles ? 'none' : 'underline')};
    background-color: ${({ disabled, hoverStyles }) => {
    if (disabled) {
      return 'transparent';
    }
    return hoverStyles ? 'rgba(0, 0, 0, 0.04) !important' : 'transparent';
  }};
  }
  
  &:active,
  &:focus {
    outline: none;
    background-color: transparent;
  }
  
  &:disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.5);
  }
`;
