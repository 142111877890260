import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { SETTINGS_QUERY, UPDATE_SETTINGS_MUTATION } from './query';
import { Form, Field } from 'react-final-form';
import CheckboxField from '../CheckboxField';
import CurrentPagePortal from '../CurrentPagePortal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-duotone-svg-icons/faCog';
import { Container } from 'reactstrap';
import SimpleButton from '../SimpleButton';
import { faSave } from '@fortawesome/pro-duotone-svg-icons/faSave';

const Settings = () => {
  const query = useQuery(SETTINGS_QUERY);
  const [updateSettings, { loading: updatingSettings }] = useMutation(UPDATE_SETTINGS_MUTATION);

  return (
    <>
      <CurrentPagePortal>
        <h2>
          <FontAwesomeIcon icon={faCog} fixedWidth className="mr-2" />
          Settings
        </h2>
      </CurrentPagePortal>
      <Container>
        {query.data && (
          <Form
            onSubmit={(values) => updateSettings({ variables: { data: values } })}
            initialValues={query.data.user.settings}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <Field
                    name="sendAppNotificationForCreationOfOwnJob"
                    label="Send me an in-app notification when I create a job"
                    type="checkbox"
                    component={CheckboxField}
                  />
                </div>
                <div>
                  <Field
                    name="sendEmailNotificationForCreationOfOwnJob"
                    label="Send me an email notification when I create a job"
                    type="checkbox"
                    component={CheckboxField}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <SimpleButton icon={faSave} color="green" loading={updatingSettings}>
                    Save changes
                  </SimpleButton>
                </div>
              </form>
            )}
          />
        )}
      </Container>
    </>
  );
};

export default Settings;
